import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

var classNames = require("classnames")
var showdown = require("showdown")

function createHtml(html) {
  return { __html: html }
}

class MarkdownSection extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const converter = new showdown.Converter()

    let text = this.props.textSection
    let html = converter.makeHtml(text)

    return (
        <section className="body-sections section joshua-tree-content">
          <div className="columns">
            <div className={`column is-${this.props.sideColumnIs}`}></div>
            <div
                  className="column"
                  dangerouslySetInnerHTML={createHtml(
                    converter.makeHtml(html)
                  )}
                ></div>
            <div className={`column is-${this.props.sideColumnIs}`}></div>
          </div>
        </section>
    )
  }
}

MarkdownSection.propTypes = {
    textSection: PropTypes.string,
  sideColumnIs: PropTypes.number,
}

export default MarkdownSection
