import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import TheaterVideo from "../Theater/TheaterVideo"
import ImageMeta from "../ImageMeta"

var classNames = require("classnames")
var showdown = require("showdown")

function createHtml(html) {
  return { __html: html }
}

class HeadingBlurbMedia extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const converter = new showdown.Converter()
    return (
      <section className="body-sections section heading-blurb-video">
        {this.props.heading && (
          <div className="columns">
            <div
              className={`column is-${this.props.headingSideColumnIs}`}
            ></div>
            <div className="column">
              <h3 style={{ marginBottom: this.props.paragraph ? "" : "2rem" }}>
                {this.props.heading}
              </h3>
              {this.props.paragraph && (
                <div
                  style={{ marginBottom: "2rem" }}
                  dangerouslySetInnerHTML={createHtml(
                    converter.makeHtml(this.props.paragraph)
                  )}
                ></div>
              )}
            </div>
            <div
              className={`column is-${this.props.headingSideColumnIs}`}
            ></div>
          </div>
        )}
        <div className="columns">
          {!this.props.isMediaFullWidth && <div className={`column is-${this.props.videoSideColumnIs}`}></div>}
          <div className="column">
            <TheaterVideo
              videoUrl={`https://www.youtube.com/watch?v=${this.props.youtube}`}
              controls={true}
              playing={true}
              overVideo={true}
              hasWatchVideo={true}
            >
              <ImageMeta
                cloudName="nuvolum"
                publicId={this.props.youtubePhotoPublicId}
                width="auto"
                responsive
                responsiveUseBreakpoints="true"
              ></ImageMeta>
            </TheaterVideo>
          </div>
          {!this.props.isMediaFullWidth && <div className={`column is-${this.props.videoSideColumnIs}`}></div>}
        </div>
      </section>
    )
  }
}

HeadingBlurbMedia.propTypes = {
  headingSideColumnIs: PropTypes.number,
  isMediaFullWidth: PropTypes.bool,
  videoSideColumnIs: PropTypes.number,
  heading: PropTypes.string,
  paragraph: PropTypes.string,
  youtube: PropTypes.string,
  youtubePhotoPublicId: PropTypes.string,
}

export default HeadingBlurbMedia
